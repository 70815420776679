import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {AbstractGuard} from "@/app/guards/strategies/abstract-guard";

export class DefaultGuard extends AbstractGuard {

    constructor(
        private to: RouteLocationNormalized,
        private from: RouteLocationNormalized,
        private next: NavigationGuardNext
    ) {
        super();
    }

    handle() {
        this.next();
    }
}