<script setup lang="ts">
import {defineProps, defineEmits, ref} from "vue";
import {IEmits, IProps} from "@/shared/ui/BaseMessage/DefaultErrorMessage/types";

// define props
const props = defineProps<IProps>();

// define emits
const emits = defineEmits<IEmits>();


// define properties
const timeDivElement = ref<HTMLDivElement|null>(null);
const currentTime = ref<number>(props.time / 1000);

// define methods
const close = () => {
  emits('close');

}
const timeToPercent = () => {
  const percent = ((currentTime.value / props.time) * 100000).toFixed(4) + '%';
  const timeDivElementExists = timeDivElement.value !== null;
  if (timeDivElementExists) {
    (timeDivElement.value as HTMLDivElement)
        .style
        .background = `linear-gradient(90deg, #E1371F ${percent}, #383737 ${percent})`;
  }
}

const timeInterval = setInterval(() => {
  currentTime.value -= 0.1;
  timeToPercent()
}, 100);
setTimeout(() => {
  clearInterval(timeInterval);
}, props.time)
</script>

<template>
  <div class="flex flex-col max-w-[28rem] bg-bluish-black-100 rounded-lg">
    <div class="flex flex-row justify-end">
      <div class="relative  top-2 right-2 cursor-pointer"
           @click="close"
      >
        <img class="w-3 h-3"
             src="@/shared/assets/images/x.webp"
             alt="Закрыть"
        >
      </div>
    </div>
    <div class="text-white">
      <div class="flex flex-row items-center">
        <span class="text-titian-200 text-xl font-semibold ml-2 mr-4">✘</span>
        <div class="flex flex-col">
          <span class="font-medium text-xs break-words">{{ title }}</span>
          <div class="w-80 mr-6 mt-1">
            <p class="font-light text-xs break-words">
            <slot/>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full rounded-b-lg mt-2 h-1"
         ref="timeDivElement"
    ></div>
  </div>
</template>

<style scoped>

</style>