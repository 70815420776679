import axios from "@/shared/lib/axios";
import {Store} from "vuex";
import {DataResponse, User} from "@/widgets/Navbar/ui/Authorized/types/api/get-user-data";
import {API_ROUTE} from "@/shared/config/app";
import {Ref} from "vue";
import {AxiosError, isAxiosError} from "axios";
import {ResponseStatus} from "@/shared/types/api/response-status";
import {MutationsTypes} from "@/shared/types/store-modules/user";

export default async (store: Store<any>, user: Ref<User|undefined>): Promise<void> => {
    try {
        const response =
            await axios(store).post<DataResponse>(API_ROUTE + 'v1/users/navbar-data');
        user.value = response.data.user;
    } catch (error) {
        if (isAxiosError(error)) {
            handleAxiosErrors(store, error);
        }
    }
}

const handleAxiosErrors = (store: Store<any>, error: AxiosError) => {
    const status = error.status;
    if (status === ResponseStatus.UNAUTHORIZED) {
        localStorage.removeItem('access_token')
        store.commit(`user/${MutationsTypes.SET_ACCESS_TOKEN}`, null);
    }
}