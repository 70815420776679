<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import {useStore} from "vuex";
import {ComponentPublicInstance, nextTick, onMounted, onUnmounted, ref} from "vue";
import getUserData from "@/widgets/Navbar/ui/Authorized/api/get-user-data";
import {User} from "@/widgets/Navbar/ui/Authorized/types/api/get-user-data";
import {ProfileMenu} from "@/features/ProfileMenu";

// define store
const store = useStore();

// define properties
const user = ref<User>();

onMounted(async () => {
  await getUserData(store, user);
})

// define properties
const menu = ref<ComponentPublicInstance | null>(null);
const avatar = ref<HTMLLIElement | null>(null);
const menuIsVisible = ref<boolean>(false);

// define methods
const toggle = () => {
  setTimeout(() => {
    menuIsVisible.value = !menuIsVisible.value;
  }, 50)
};

// define event hooks
const handleClickOutside = (event: MouseEvent) => {
  setTimeout(async () => {
    if (menu.value && avatar.value) {
      const clickOutside = !((menu.value as ComponentPublicInstance).$el as HTMLDivElement)
          .contains(event.target as Node);
      const clickOnAvatar = (avatar.value as HTMLLIElement).contains(event.target as Node);
      if (clickOutside && !clickOnAvatar) {
        menuIsVisible.value = false;
      }
    }

    await nextTick();
  }, 50);
};

// set up event listeners
onMounted(() => {
  document.addEventListener('mousedown', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('mousedown', handleClickOutside);
});
</script>

<template>
  <li title="Уведомления"
      class="ml-[2px] sm:ml-4"
  >
    <img class="ml-[2px] sm:ml-4 w-2 h-2 sm:w-4 sm:h-4 lg:w-6 lg:h-6 xl:w-7 xl:h-7 4xl:w-10 4xl:h-10 cursor-pointer"
         src="@/widgets/Navbar/assets/img/notification.webp"
         alt="Уведомления"
    >
  </li>
  <li v-if="user"
      :title="`Пользователь ${user.login}`"
      @click="toggle"
      ref="avatar"
  >
    <img class="ml-[2px] sm:ml-4 w-3 h-3 sm:w-5 sm:h-5
                lg:w-8 lg:h-8 xl:w-10 xl:h-10 4xl:w-12 4xl:h-12
                rounded-md cursor-pointer"
         :src="user.avatar.url"
         alt="Аватар"
    >
  </li>
  <ProfileMenu v-if="user && menuIsVisible"
               ref="menu"
               :login="user.login"
               class="absolute top-36 z-50"
  />
</template>

<style scoped>

</style>