<script setup lang="ts">
import {defineProps, defineEmits} from "vue";
import {IEmits, IProps} from "@/shared/ui/BaseMessage/types";
import {MessageFactory} from "@/shared/ui/BaseMessage/hooks";

// define props
const props = defineProps<IProps>();

// define emits
const emits = defineEmits<IEmits>()

// define methods
const close = () => {
  emits('close');
}

// define component
const component = MessageFactory(props.type);
</script>

<template>
  <component class="h-fit"
             :is="component"
             :title="title"
             :time="time"
             @close="close"
  >
    <slot/>
  </component>
</template>

<style scoped>

</style>