import {IPropsTypes, BaseMessageFactory} from "@/shared/ui/BaseMessage/types";
import {DefaultErrorMessage} from "@/shared/ui/BaseMessage/DefaultErrorMessage";
import {Component} from "vue";

export const MessageFactory: BaseMessageFactory = (type) => {
    let component: Component<IPropsTypes> = DefaultErrorMessage;

    switch (type) {
        case "default-error":
            component = DefaultErrorMessage;
            break;
        default:
            break;
    }

    return component;
}