import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {getAccessToken} from "@/shared/lib/store";
import {AppRouteRecord, TRoutesNames} from "@/shared/types/router";
import {AbstractGuard} from "@/app/guards/strategies/abstract-guard";

export class AnonymousOnlyGuard extends AbstractGuard{
    constructor(
        private to: RouteLocationNormalized,
        private from: RouteLocationNormalized,
        private next: NavigationGuardNext
    ) {
        super();
    }

    handle() {
        if (!getAccessToken()) {
            this.next()
            return;
        }
        this.next({name: "home"} as Omit<AppRouteRecord, "name"> & {name: TRoutesNames})
    }
}