import {BaseMessageTypes} from "@/shared/ui/BaseMessage/types";

export type Message = {
    id: string
    type?: BaseMessageTypes
    title: string
    content: string
    time: number
}

export type MessageWithTime = {
    message: Omit<Message, "id" | "time">
    time: number
}

export type Timeout = {
    message_id: string,
    timeout_id: number
};

export interface State {
    messages: Message[]
    timeouts: Timeout[]
}

export enum GettersTypes {
    GET_MESSAGES = 'GET_MESSAGES',
    GET_MESSAGE = 'GET_MESSAGE',
    GET_TIMEOUT = 'GET_TIMEOUT',
    GET_TIMEOUTS = 'GET_TIMEOUTS',
}

export enum MutationsTypes {
    ADD_MESSAGE = 'ADD_MESSAGE',
    ADD_MESSAGES = 'ADD_MESSAGES',
    CLEAR_MESSAGE = 'CLEAR_MESSAGE',
    CLEAR_MESSAGES = 'CLEAR_MESSAGES',
    ADD_TIMEOUT = 'ADD_TIMEOUT',
    ADD_TIMEOUTS = 'ADD_TIMEOUTS',
    CLEAR_TIMEOUT = 'CLEAR_TIMEOUT',
    CLEAR_TIMEOUTS = 'CLEAR_TIMEOUTS',
}

export interface Getters<S = State> {
    [GettersTypes.GET_MESSAGE](state: S, payload: string): Message | undefined

    [GettersTypes.GET_MESSAGES](state: S): Message[]

    [GettersTypes.GET_TIMEOUT](state: S, payload: string | number): Timeout | undefined

    [GettersTypes.GET_TIMEOUTS](state: S): Timeout[]
}

export interface Mutations<S = State> {
    [MutationsTypes.ADD_MESSAGE](state: S, payload: MessageWithTime): void

    [MutationsTypes.ADD_MESSAGES](state: S, payload: MessageWithTime[]): void

    [MutationsTypes.CLEAR_MESSAGE](state: S, payload: string ): void
}