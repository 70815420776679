import axios from "@/shared/lib/axios";
import {Store} from "vuex";
import {API_ROUTE} from "@/shared/config/app";

export default async (store: Store<any>, removeToken: () => void): Promise<void> => {
    try {
        const response = await axios(store).post(API_ROUTE + 'v1/users/auth/logout');
        removeToken();
    } catch (error) {
        removeToken();
    }
}