<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">

</script>

<template>
  <footer class="bg-bluish-black-100">
    <div class="mx-auto w-full max-w-screen-xl">
      <div class="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
        <div>
          <h2 class="mb-4 text-sm font-semibold text-gray-900 dark:text-white">animeheaven.org</h2>
          <ul class="text-[#D9D9D9] text-sm font-medium">
            <li class="mb-4">
              <a href="#" class=" hover:underline">Правообладателям</a>
            </li>
            <li class="mb-4">
              <a href="#" class="hover:underline">Политика конфиденциальности </a>
            </li>
          </ul>
        </div>
        <div>
          <ul class="text-[#D9D9D9] text-sm font-medium">
            <li class="mb-4">
              <a href="#" class="flex flex-row items-center space-x-2 hover:underline">
                <img src="@/widgets/Footer/assets/img/vk.webp"
                     alt="VK"
                     class="w-4 h-4"
                >
                <span>VK</span>
              </a>
            </li>
            <li class="mb-4">
              <a href="#" class="flex flex-row items-center space-x-2 hover:underline">
                <img src="@/widgets/Footer/assets/img/telegram.webp"
                     alt="Telegram"
                     class="w-4 h-4"
                >
                <span>Telegram</span>
              </a>
            </li>
            <li class="mb-4">
              <a href="#" class="flex flex-row items-center space-x-2 hover:underline">
                <img src="@/widgets/Footer/assets/img/discord.webp"
                     alt="Discord"
                     class="w-4 h-4"
                >
                <span>Discord</span>
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul class="text-[#D9D9D9] text-sm font-medium">
            <li class="mb-4">
              <a href="#" class="flex flex-row items-center space-x-2 hover:underline">
                <img src="@/widgets/Footer/assets/img/android.webp"
                     alt="Android"
                     class="w-4 h-4"
                >
                <span>Android</span>
              </a>
            </li>
            <li class="mb-4">
              <a href="#" class="flex flex-row items-center space-x-2 hover:underline">
                <img src="@/widgets/Footer/assets/img/ios.webp"
                     alt="IOS"
                     class="w-4 h-4"
                >
                <span>IOS</span>
              </a>
            </li>
            <li class="mb-4">
              <a href="#" class="flex flex-row items-center space-x-2 hover:underline">
                <img src="@/widgets/Footer/assets/img/windows.webp"
                     alt="Windows"
                     class="w-4 h-4"
                >
                <span>Windows</span>
              </a>
            </li>
            <li class="mb-4">
              <a href="#" class="flex flex-row items-center space-x-2 hover:underline">
                <img src="@/widgets/Footer/assets/img/linux.webp"
                     alt="Linux"
                     class="w-4 h-4"
                >
                <span>Linux</span>
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul class="text-[#D9D9D9] text-sm font-medium">
            <li class="mb-4">
              <a href="#" class="hover:underline">О нас</a>
            </li>
            <li class="mb-4">
              <a href="#" class="hover:underline">Поддержать нас</a>
            </li>
            <li class="mb-4">
              <a href="#" class="hover:underline">Контакты</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>

</style>