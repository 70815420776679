import { createStore } from 'vuex'
import vuelidate from "@/shared/consts/store-modules/vuelidate";
import messagesContainer from "@/shared/consts/store-modules/messages-container";
import user from "@/shared/consts/store-modules/user";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    vuelidate,
    messagesContainer,
    user,
  }
})
