import {createRouter, createWebHistory} from 'vue-router'
import {routes} from "@/shared/consts";
import {NavigationGuard} from "@/app/guards/navigation-guard";


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
})

router.beforeEach((to, from, next) => {
  new NavigationGuard(to, from, next).handle()
})

export default router
