import {
    Getters,
    GettersTypes,
    Message,
    Mutations,
    MutationsTypes,
    State,
} from "@/shared/types/store-modules/messages-container";
import {GetterTree, Module, MutationTree} from "vuex";

const state: State = {
    messages: [],
    timeouts: [],
}

const getters: GetterTree<State, any> & Getters = {
    [GettersTypes.GET_MESSAGE]: (state, payload) => {
        return state.messages.find(message => message.id === payload)
    },
    [GettersTypes.GET_MESSAGES]: (state) => state.messages,
    [GettersTypes.GET_TIMEOUT]: (state, payload) => {
        return state.timeouts
            .find(timeout => timeout.message_id === payload || timeout.timeout_id === payload);
    },
    [GettersTypes.GET_TIMEOUTS]: (state) => state.timeouts,
}

const mutations: MutationTree<State> & Mutations = {
    [MutationsTypes.ADD_MESSAGE]: (state, payload) => {
        const id = Date.now().toString();
        const message: Message = {id, ...payload.message, time: payload.time};
        state.messages.push(message);
        const timeout = setTimeout(() => {
            const messageIndex = state
                .messages
                .findIndex((message) => message.id === id);
            const messageIsExists = messageIndex !== -1;
            if (messageIsExists) {
                state.messages.splice(messageIndex, 1)
            }

        }, payload.time)
        state.timeouts.push({message_id: id, timeout_id: timeout})
        setTimeout(() => {
            const timeoutIndex = state
                .timeouts
                .findIndex((timeout) => timeout.message_id === id)
            const timeoutIsExists = timeoutIndex !== -1;
            if (timeoutIsExists) {
                state.timeouts.splice(timeoutIndex, 1);
            }
        }, payload.time)

    },
    [MutationsTypes.ADD_MESSAGES]: (state, payload) => {
        for (const payloadElement of payload) {
            const id = Date.now().toString();
            const message: Message = {id, ...payloadElement.message, time: payloadElement.time};
            state.messages.push(message);
            const timeout = setTimeout(() => {
                const messageIndex = state
                    .messages
                    .findIndex((message) => message.id === id);
                const messageIsExists = messageIndex !== -1;
                if (messageIsExists) {
                    state.messages.splice(messageIndex, 1)
                }

            }, payloadElement.time);
            state.timeouts.push({message_id: id, timeout_id: timeout})
            setTimeout(() => {
                const timeoutIndex = state
                    .timeouts
                    .findIndex((timeout) => timeout.message_id === id)
                const timeoutIsExists = timeoutIndex !== -1;
                if (timeoutIsExists) {
                    state.timeouts.splice(timeoutIndex, 1);
                }
            }, payloadElement.time)
        }
    },
    [MutationsTypes.CLEAR_MESSAGE]: (state, payload) => {
        const stateIndex = state
            .messages
            .findIndex(message => message.id === payload);
        const stateIsExists = stateIndex !== -1;
        if (stateIsExists) {
            state.messages.splice(stateIndex, 1);
            const timeoutIndex = state
                .timeouts
                .findIndex(timeout => timeout.message_id === payload);
            const timeoutIsExists = timeoutIndex !== -1;
            if (timeoutIsExists) {
                clearTimeout(state.timeouts[timeoutIndex].timeout_id);
                state.timeouts.splice(timeoutIndex, 1);
            }
        }
    },
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
} as Module<State, any>