import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {Guard} from "@/app/types/guards";
import {TRoutesNames} from "@/shared/types/router";
import {AnonymousOnlyGuard} from "@/app/guards/strategies/anonymous-only-guard";
import {DefaultGuard} from "@/app/guards/strategies/default-guard";

export class NavigationGuard {
    private strategy: Guard | null = null;

    constructor(
        private to: RouteLocationNormalized,
        private from: RouteLocationNormalized,
        private next: NavigationGuardNext
    ) {
    }

    handle() {
        this.getStrategy();
        (this.strategy as Guard).handle();
    }

    private getStrategy() {
        const name = this.to.name as TRoutesNames
        switch (name) {
            case "users.login":
                this.strategy = new AnonymousOnlyGuard(this.to, this.from, this.next);
                break;
            case "users.register":
                this.strategy = new AnonymousOnlyGuard(this.to, this.from, this.next)
                break;
            case "users.email.verify":
                this.strategy = new AnonymousOnlyGuard(this.to, this.from, this.next)
                break;
            default:
                this.strategy = new DefaultGuard(this.to, this.from, this.next);
        }
    }
}