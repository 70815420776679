<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import {BaseRouterLink} from "@/shared/ui/BaseRouterLink";
import {computed} from "vue";
import {getAccessToken} from "@/shared/lib/store";
import {NotAuthorized} from "@/widgets/Navbar/ui/NotAuthorized";
import {Authorized} from "@/widgets/Navbar/ui/Authorized";

// define computed properties
const accessToken = computed<string | null>(() => getAccessToken());
</script>

<template>
  <div class="h-fit">
    <div class="min-w-full bg-black lg:container mx-auto">
      <div class="min-w-0">
        <nav class="w-screen">
          <div class="flex md:flex-row xl:flex-wrap items-center justify-between mx-auto
                      py-2 lg:py-3 xl:py-4
                      md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 4xl:max-w-screen-4xl"
          >
            <BaseRouterLink>
              <span class="self-center text-lg md:text-xl lg:text-2xl xl:text-3xl 4xl:text-6xl
                           4xl:py-4
                           font-bold whitespace-nowrap
                           dark:text-white uppercase"
              ><span class="text-dark-purple">A</span>nime Heaven</span>
            </BaseRouterLink>
            <div class="hidden md:block w-auto">
              <ul class="flex flex-wrap space-x-1 sm:space-x-2 md:space-x-3 lg:space-x-4 xl:space-x-11
              text-xs 4xl:text-4xl md:text-base lg:text-lg xl:text-[1.0625rem] font-semibold">
                <li>
                  <BaseRouterLink class="text-white hover:text-heliotrope-300">Главная</BaseRouterLink>
                </li>
                <li>
                  <BaseRouterLink class="text-white hover:text-heliotrope-300">Топ-100</BaseRouterLink>
                </li>
                <li>
                  <BaseRouterLink class="text-white hover:text-heliotrope-300">W2G</BaseRouterLink>
                </li>
                <li>
                  <BaseRouterLink class="text-white hover:text-heliotrope-300">Случайное</BaseRouterLink>
                </li>
                <li>
                  <BaseRouterLink class="text-white hover:text-heliotrope-300">&#183; &#183; &#183;</BaseRouterLink>
                </li>
              </ul>
            </div>
            <div class="w-auto hidden md:flex flex-wrap items-center">
              <ul class="flex flex-wrap items-center lg:space-x-1 text-4xs sm:text-2xs lg:text-lg xl:text-md font-semibold">
                <li>
                  <img class="w-2 h-2 sm:w-4 sm:h-4 lg:w-6 lg:h-6 xl:w-7 xl:h-7 4xl:w-10 4xl:h-10 cursor-pointer"
                       src="@/widgets/Navbar/assets/img/moon.webp"
                       alt="Переключатель темы">
                </li>
                <NotAuthorized v-if="!accessToken"/>
                <Authorized v-if="accessToken"/>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<style>
</style>