<script setup lang="ts">
import {useStore} from "vuex";
import {computed} from "vue";
import {GettersTypes, Message, MutationsTypes} from "@/shared/types/store-modules/messages-container";
import {BaseMessage} from "@/shared/ui/BaseMessage";

// define store
const store = useStore();

// define computed properties
const messages = computed<Message[]>(() => store.getters[`messagesContainer/${GettersTypes.GET_MESSAGES}`]);

// define methods
const closeMessage = (messageId: string) => {
  store.commit(`messagesContainer/${MutationsTypes.CLEAR_MESSAGE}`, messageId)
}
</script>

<template>
  <div class="message-container">
    <transition-group name="message-transition" tag="div">
      <BaseMessage v-for="message in messages"
                   :key="message.id"
                   :title="message.title"
                   :type="message.type"
                   :time="message.time"
                   @close="closeMessage(message.id)"
                   class="message-item"
      >{{ message.content }}</BaseMessage>
    </transition-group>
  </div></template>

<style lang="scss" scoped>
.message-container {
  @apply fixed bottom-2 right-2 flex flex-col items-center w-fit;
}

.message-transition-enter-active, .message-transition-leave-active {
  @apply transition ease-in-out duration-500;
}

.message-transition-enter-from {
  @apply transform translate-y-[100%] opacity-0;
}

.message-transition-enter-to {
  @apply transform translate-y-0 opacity-100;
}

.message-transition-leave-from {
  @apply transform translate-y-0 opacity-100;
}

.message-transition-leave-to {
  @apply transform translate-y-[100%] opacity-0;
}

.message-item {
  @apply mt-2
}
</style>