import {Message, MutationsTypes as MessageMutationsTypes} from "@/shared/types/store-modules/messages-container";
import store from "@/app/providers/store";
import {
    MutationsTypes as UserMutationsTypes,
    GettersTypes as UserGettersTypes,
} from "@/shared/types/store-modules/user";

export const addMessageToContainer = (message: Omit<Message, "id" | "time">, time: number) => {
    const payload = {
        message,
        time,
    };
    store.commit(`messagesContainer/${MessageMutationsTypes.ADD_MESSAGE}`, payload);
}

export const getAccessToken = (): string|null => {
    return store.getters[`user/${UserGettersTypes.GET_ACCESS_TOKEN}`];
}

export const updateAccessToken = (): void => {
    store.commit(`user/${UserMutationsTypes.SET_ACCESS_TOKEN}`, localStorage.getItem('access_token'))
}