<script setup lang="ts">
import {DefaultLayout} from "@/app/layouts";
import {Routing} from "@/pages/routing";
import {updateAccessToken} from "@/shared/lib/store";
import {onMounted} from "vue";

onMounted(() => {
  updateAccessToken();
})
</script>

<template>
  <div class="font-montserrat">
    <DefaultLayout>
      <Routing/>
    </DefaultLayout>
  </div>
</template>

<style>
@import "@/app/index.css";
#app {
  background-image: url("@/app/assets/images/background.png");
  background-repeat: repeat-y;
  background-size: 100% auto;
  overflow-x: hidden;
}
</style>