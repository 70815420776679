import {AppRouteRecord} from "@/shared/types/router";


export const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/pages/home/ui/HomePage.vue'),
    },
    {
        path: '/:anime',
        name: 'show',
        component: () => import('@/pages/show/ui/ShowPage.vue'),
    },
    {
        path: '/animes/search',
        name: 'animes.search',
        component: () => import('@/pages/search/ui/SearchPage.vue'),
    },
    {
        path: '/register',
        name: 'users.register',
        component: () => import('@/pages/register/ui/RegisterPage.vue'),
    },
    {
        path: '/login',
        name: 'users.login',
        component: () => import('@/pages/login/ui/LoginPage.vue'),
    },
    {
        path: '/verify-email/:id/:hash',
        name: 'users.email.verify',
        component: () => import('@/pages/email-verification/ui/EmailVerificationPage.vue'),
    }
] as const satisfies readonly AppRouteRecord[];