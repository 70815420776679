import {
    Getters,
    GettersTypes,
    Mutations,
    MutationsTypes,
    State,
} from "@/shared/types/store-modules/user";
import {GetterTree, Module, MutationTree} from "vuex";

const state: State = {
    accessToken: null,
}

const getters: GetterTree<State, any> & Getters = {
    [GettersTypes.GET_ACCESS_TOKEN]: (state) => state.accessToken
}

const mutations: MutationTree<State> & Mutations = {
    [MutationsTypes.SET_ACCESS_TOKEN]: (state, payload) => {
        state.accessToken = payload;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations
} as Module<State, any>